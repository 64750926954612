import React from "react";
import MapContainer from "./components/Map";
import Widget from "./components/Widget/Widget";
import { useGTM } from "./hooks/use-gtm";

const App = (props) => {
  useGTM();
  // const printSelectedPoint = (pickupPoint) => {
  //   console.log(pickupPoint);
  // };

  // const printSelectedPoint2 = (pickupPoint) => {
  //   console.log(
  //     `el punto se llama ${pickupPoint.agency_name} y su dirección es ${pickupPoint.location.street_name} ${pickupPoint.location.street_number}, Región: ${pickupPoint.location.state_name}`
  //   );
  // };

  if (!navigator.geolocation) {
    alert("tu navegador no tiene opción de geolocaclización");
    throw new Error("tu navegador no tiene opción de geolocaclización");
  }

  // return (
  //   <>
  //     {/* <div className="h-100 d-flex flex-column">
  //       <MapContainer
  //         onPickupSelection={printSelectedPoint}
  //         isMarkerShown={true}
  //       />
  //     </div> */}
  //     <div className="h-100 d-flex flex-column">
  //       <Widget
  //         onChangeSelection={printSelectedPoint2}
  //         configs="h-100 w-100 d-flex flex-column"
  //         //address="Av. Argentina 01683, Antofagasta, Chile"
  //         //address="Las Golondrinas oriente 4053"
  //         //address="Los Prunos 6884, la reina"
  //         //address="Antofagasta"
  //       />
  //     </div>
  //   </>
  // );

  return (
    <>
      <Widget
        onChangeSelection={props.outputFunction}
        configs={props.configs}
        address={props.address}
      />
      <style>{`pu-do, pu-do > div { height: 100%;}`}</style>
    </>
  );
};

export default App;
