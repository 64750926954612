export const getPickupPoints = async () => {
  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    country_id: "CL",
    status: "active",
    //city_id: "13124",
    // state_id: "13",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    console.log("Trayendo data");
    const response = await fetch(process.env.REACT_API_URL, requestOptions);
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("no response", error);
  }
};
