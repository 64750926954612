import React, {
  memo,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  MarkerClusterer,
} from "@react-google-maps/api";
import SearchBar from "./Search";
import ListBox from "./List";
import { getPickupPoints } from "../services/getPickupPoints";
import {
  containerStyle,
  mapStyle,
  createPoint,
  getDistancesAndSortAgencies,
} from "../utilities/utilities";
import {  dispatchMessage } from "../utilities/dispatcherMessage"

const MapContainer = ({ isMarkerShown, onPickupSelection, startAddress }) => {

  const [center, setCenter] = useState({
    lat: -33.4488897,
    lng: -70.6692655,
  });
  const [currentAgencies, setAgencies] = useState();
  const [map, setMap] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [topAgency, setTopAgency] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  const [actualLocation, setActualLocation] = useState(center);
  const [zoom, setZoom] = useState(14);

  useEffect(() => {
    navigator?.geolocation.getCurrentPosition((position) => {
      const newCenter = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      setActualLocation(newCenter);
      setCenter(newCenter);
    });

    getPickupPoints().then((agencies) => {
      setAgencies(agencies);
      setIsDataLoaded(true);
    });
  }, []);

  const agencies = useMemo(() => {
    if (!currentAgencies) return;
    return getDistancesAndSortAgencies(currentAgencies, actualLocation);
  }, [actualLocation, currentAgencies]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const updateCenterAndActualLocation = useCallback((latLng) => {
    // console.log("map updating center", latLng);
    setCenter(latLng);
    setActualLocation(latLng);
  }, []);

  const handleBluexMarkerClick = (id) => {
    // console.log("id pressed", id);
    const auxAgency = agencies.agencies.find(
      (agency) => agency.agency_id === id
    );
    // console.log("new selected agency:", auxAgency);

    const listBox = document.querySelector(".list-box-container");
    listBox.scrollTop = 0;

    setTopAgency(auxAgency);
    setSelectedAgency(auxAgency);
    const newCenter = {
      lat: auxAgency.location.geolocation.latitude,
      lng: auxAgency.location.geolocation.longitude,
    };
    setCenter(newCenter);

    //setAgencies(auxAgency);
    return auxAgency;
  };

  const handleClickListboxTitle = (id) => {
    const body = document.querySelector("body");
    body.scrollTo(0, 0);

    const auxAgency = agencies.agencies.find(
      (agency) => agency.agency_id === id
    );
    window.innerWidth < 768 && setTopAgency(auxAgency);
    setSelectedAgency(auxAgency);
    // setTopAgency();
    const newCenter = {
      lat: auxAgency.location.geolocation.latitude,
      lng: auxAgency.location.geolocation.longitude,
    };
    setCenter(newCenter);
    setZoom(zoom === 18 ? 17 : 18);
    // console.log("Listbox Pulsado", id);
    try {

      if(onPickupSelection) {
        onPickupSelection(auxAgency);
      }
     
    } catch (error) {
      console.log("onPickupSelection prop must be a function");
    }
  };


  return isLoaded ? (
    <>
      <div className="hide-on-cellphone">
        <SearchBar
          className="position-absolute search-bar-container "
          updateMapPosition={updateCenterAndActualLocation}
          startAddress={startAddress}
        />
      </div>
      <div className="position-relative map-and-listbox-container-w-nobanner">
        <div className="map-container w-100 position-relative ">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{
              lat: center.lat,
              lng: center.lng,
            }}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            id={map}
            options={{
              gestureHandling: "greedy",
              disableDefaultUI: true,
              zoomControl: true,
              styles: mapStyle,
            }}
          >
            {isMarkerShown && (
              <MarkerF
                position={actualLocation}
                icon={{
                  url: "https://static.blue.cl/images/map_markers/marker_blue_here.png",
                  scaledSize: new window.google.maps.Size(51, 60),
                }}
              />
            )}
            {isDataLoaded && (
              <MarkerClusterer
                options={{
                  imagePath:
                    "https://static.blue.cl/images/map_markers/marker_cluster_b",
                  // "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                  gridSize: 90,
                  zoomOnClick: true,
                  averageCenter: true,
                  maxZoom: 14,
                  imageExtension: "png",
                  imageSizes: [40, 60, 80],
                  //title: "Titulo",
                }}
              >
                {(clusterer) =>
                  agencies &&
                  agencies.agencies
                    .filter((agency) => agency.status === "active")
                    .map((agency) => {
                      const position = {
                        lat: agency.location.geolocation.latitude,
                        lng: agency.location.geolocation.longitude,
                      };
                      return (
                        <MarkerF
                          key={agency.agency_id}
                          position={position}
                          icon={{
                            url: "https://static.blue.cl/images/map_markers/marker_blue_standard-1.png",
                            scaledSize: new window.google.maps.Size(34, 40),
                          }}
                          clusterer={clusterer}
                          onClick={() => {
                            handleBluexMarkerClick(agency.agency_id);
                            dispatchMessage("pudo:select", agency);
                          }}
                        />
                      );
                    })
                }
              </MarkerClusterer>
            )}
          </GoogleMap>
        </div>
        <div className=" hide-on-desktop-and-tablet">
              <SearchBar
                className={`position-relative z-index-9999`}
                updateMapPosition={updateCenterAndActualLocation}
            
              />     
          </div>
        {isDataLoaded && (
          <div className="list-box-container">
            {topAgency && (
              <div
                className={
                  selectedAgency?.agency_id === topAgency.agency_id
                    ? "selected-list-box"
                    : ""
                }
              >
                <ListBox
                  point={createPoint(topAgency)}
                  key={topAgency.agency_id}
                  agency={topAgency}
                  onClickTitle={handleClickListboxTitle}
                />
              </div>
            )}

            {agencies &&
              agencies.agencies
                .filter(
                  (agency) =>
                    agency.status === "active" &&
                    agency.agency_id !== topAgency?.agency_id
                )
                .map((agency) => {
                  const point = createPoint(agency);

                  return (
                    <div
                      key={agency.agency_id}
                      className={
                        selectedAgency?.agency_id === agency.agency_id
                          ? "selected-list-box"
                          : ""
                      }
                    >
                      <ListBox
                        point={point}
                        key={agency.agency_id}
                        agency={agency}
                        onClickTitle={handleClickListboxTitle}
                      />
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </>
  ) : (
    <>
      <div className="p-4">
        <p className="fs-1">Cargando...</p>
      </div>
    </>
  );
};

export default memo(MapContainer);
