import React from "react";
import MapContainer from "../Map";

const Widget = ({ onChangeSelection, address, configs }) => {

  return (
    <div className={configs}>
      <MapContainer
        onPickupSelection={onChangeSelection}
        isMarkerShown={true}
        startAddress={address}
      />
    </div>
  );
};

export default Widget;
